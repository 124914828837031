<template>
  <div class="nk-block-head nk-block-head-lg">
    <div class="nk-block-between">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title mb-0">
          {{ title }}
        </h4>
        <div class="nk-block-des">
          <p class="mb-0">Manage your {{ Settings._APP_NAME }} Companies</p>
        </div>
      </div>
      <div class="nk-block-head-content">
        <a
          href="#"
          @click.prevent="showCompanyForm = true"
          class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
          ><em class="icon ni ni-plus"></em><span>Add Company</span>
        </a>
        <a
          href="#"
          @click.prevent
          class="
            btn btn-icon btn-outline-light
            bg-white
            d-inline-flex d-sm-none
          "
          ><em class="icon ni ni-plus"></em>
        </a>
      </div>
    </div>
  </div>
  <!-- .nk-block-head -->

  <div class="nk-block">
    <div class="card">
      <div class="card-inner mb-3">
        <h6 class="letter-spacing-2 w3-text-grey size10 bold">
          <em class="icon ni ni-star-fill w3-text-yellow size16"></em> ACTIVE
          COMPANY
        </h6>
        <div class="nk-block-head nk-block-head-sm mb-3">
          <div class="nk-block-between g-3">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">
                {{ Company.company_name }}
              </h3>
            </div>
            <div class="nk-block-head-content">
              <a
                href="#"
                @click.prevent="showCompanyForm = true"
                class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                ><em class="icon ni ni-edit"></em><span>Edit</span>
              </a>
              <a
                href="#"
                @click.prevent="showCompanyForm = true"
                class="
                  btn btn-icon btn-outline-light
                  bg-white
                  d-inline-flex d-sm-none
                "
                ><em class="icon ni ni-edit"></em>
              </a>
            </div>
          </div>
        </div>
        <div class="nk-block text-soft">
          <div
            class="w3-row w3-border-top w3-border-bottom w3-border-light-grey"
          >
            <div class="w3-col m5 mb-0">UUID</div>
            <div class="w3-col m7 mb-1">
              {{ Company.uuid }}
            </div>
          </div>
          <div class="w3-row w3-border-bottom w3-border-light-grey">
            <div class="w3-col m5 mb-0">COMPANY ID</div>
            <div class="w3-col m7 mb-1">
              {{ Company.merchant_id }}
            </div>
          </div>
          <div class="w3-row w3-border-bottom w3-border-light-grey">
            <div class="w3-col m5 mb-0">PORTAL</div>
            <div class="w3-col m7 mb-1">
              <a
                :href="Settings._FRONTEND_URL + '/' + Company.merchant_id"
                target="_blank"
                class="text-base w3-mobile underline"
                >{{ Settings._FRONTEND_URL }}/{{ Company.merchant_id }}
              </a>
            </div>
          </div>
          <div class="w3-row w3-border-bottom w3-border-light-grey">
            <div class="w3-col m5 mb-0">TYPE</div>
            <div class="w3-col m7 mb-1">
              {{ Company.company_type }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-inner mb-3">
        <h6 class="letter-spacing-2 w3-text-grey size10">OTHERS COMPANIES</h6>
        <div class="card card-bordered">
          <div class="card-inner-group">
            <div
              v-for="company in Companies"
              :key="company.uuid"
              class="card-inner py-2 w3-hover-light-grey"
            >
              <div class="nk-block-head">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">
                      {{ company.company_name }}
                    </h4>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          Company URL
                          <a
                            :href="
                              Settings._FRONTEND_URL + '/' + company.merchant_id
                            "
                            target="_blank"
                            class="text-base w3-mobile underline"
                            >{{ Settings._FRONTEND_URL }}/{{
                              company.merchant_id
                            }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="nowrap">
                      <a
                        href="#"
                        @click.prevent
                        class="
                          btn btn-outline-light
                          bg-white
                          d-none d-sm-inline-flex
                          mr-1
                        "
                        ><em class="icon ni ni-star"></em>
                        <span>Set Default</span>
                      </a>
                      <a
                        href="#"
                        @click.prevent="showCompanyForm = true"
                        class="
                          btn btn-outline-light
                          bg-white
                          d-none d-sm-inline-flex
                        "
                        ><em class="icon ni ni-edit"></em><span>Edit</span>
                      </a>
                    </div>
                    <a
                      href="#"
                      @click.prevent
                      class="
                        btn btn-icon btn-outline-light
                        bg-white
                        d-inline-flex d-sm-none
                        mb-1
                      "
                      ><em class="icon ni ni-star"></em>
                    </a>
                    <a
                      href="#"
                      @click.prevent="showCompanyForm = true"
                      class="
                        btn btn-icon btn-outline-light
                        bg-white
                        d-inline-flex d-sm-none
                      "
                      ><em class="icon ni ni-edit"></em>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    const title = ref("Companies");
    const store = useStore();
    const Settings = computed(() => store.getters.settings);
    const User = computed(() => store.getters["auth/user"]);
    const Company = computed(() => store.getters["auth/userMerchant"]);
    const showCompanyForm = ref(false);
    const AllCompanies = computed(() => User.value.merchants ?? []);
    const Companies = computed(() => {
      return AllCompanies.value.filter(
        (item) => item.uuid !== Company.value.uuid
      );
    });
    return {
      title,
      Settings,
      showCompanyForm,
      User,
      Company,
      Companies,
    };
  },
};
</script>
